import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarEntry } from '../model/calendar-entry';
import { environment } from '../../environments/environment';
import { CalendarResponse } from '../model/calendar-response';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { BookingResponse } from '../model/booking-response';
import { Booking } from '../model/booking';
import { BookingsResponse } from '../model/bookings-response';
import { StatsResponse } from '../model/stats-response';
import { PaymentsResponse } from '../model/payments-response';
import { catchError } from 'rxjs/operators';
import { BaseResponse } from '../model/base-response';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  bookings$ = new BehaviorSubject<CalendarEntry[]>([]);

  failure$ = new Subject<CalendarResponse>();

  upcomingTraining$ = new BehaviorSubject<Booking>(null);

  traineeBookings$: BehaviorSubject<Booking[]> = new BehaviorSubject<Booking[]>([]);

  constructor(private http: HttpClient) {}

  async refreshBookings(start?: Date, end?: Date, activity?: string): Promise<void> {
    const startDtPath = start ? moment(start).format('YYYY-MM-DD') : '';
    const endDtPath = end ? moment(end).format('YYYY-MM-DD') : '';
    const url = `${environment.apiUrl}/bookings` + (startDtPath ? `/${startDtPath}` : '')
        + (endDtPath ? `/${endDtPath}` : '') + (activity ? `/${activity}` : '');
    try {
      const response = await this.http.get<CalendarResponse>(url).toPromise();
      if (response.code !== 100) {
        this.failure$.next(response);
      }
      else {
        this.bookings$.next(response.data);
      }
    }
    catch (err) {
      console.error(`Communication failure: ${err.message}`);
      this.failure$.next({
        code: 999,
        status: 'FAILED',
        errors: [{
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        }]
      });
    }
  }
  async book(trainingId: number, bookingId?: number): Promise<BookingResponse> {
    try {
      if (bookingId) {
        const url = `${environment.apiUrl}/bookings/book/${bookingId}/${trainingId}`;
        return await this.http.put<BookingResponse>(url, {}).toPromise();
      }
      else {
        const url = `${environment.apiUrl}/bookings/book/${trainingId}`;
        return await this.http.post<BookingResponse>(url, {}).toPromise();
      }
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      };
    }
  }

  async cancel(bookingId: number): Promise<BaseResponse<any>> {
    try {
      await this.http.post<BookingResponse>(`${environment.apiUrl}/bookings/cancel/${bookingId}`, {}).toPromise();
      return {
        code: 100,
        status: 'SUCCESS',
        errors: null
      };
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      };
    }
  }

  async getPastTrainings(limit?: number): Promise<BookingsResponse> {
    try {
      const url = `${environment.apiUrl}/bookings/past${!limit ? '' : '/' + limit}`;
      return await this.http.get<BookingsResponse>(url).toPromise();
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      };
    }
  }

  async getUpcomingTrainings(): Promise<BookingsResponse> {
    try {
      const url = `${environment.apiUrl}/bookings/upcoming`;
      return await this.http.get<BookingsResponse>(url).toPromise();
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Communication failure ' + err.message
        }]
      };
    }
  }

  async getStats(): Promise<StatsResponse> {
    try {
      const url = `${environment.apiUrl}/bookings/stats`;
      return await this.http.get<StatsResponse>(url).toPromise();
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Communication failure ' + err.message
        }]
      };
    }
  }

  async getDetails(bookingId: number): Promise<BookingResponse> {
    try {
      return await this.http.get<BookingResponse>(`${environment.apiUrl}/bookings/${bookingId}`)
          .toPromise();
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Communication failure ' + err.message
        }]
      };
    }
  }

  async getBookingsBySchedule(scheduleId: number): Promise<BookingsResponse> {
    try {
      const url = `${environment.apiUrl}/bookings/schedule/${scheduleId}`;
      const response = await this.http.get<BookingsResponse>(url).toPromise();
      if (response.code === 100) {
        this.traineeBookings$.next(response.data);
      }
      return response;
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      };
    }
  }

  async save(bookings: Booking[]): Promise<BookingsResponse> {
    try {
      if (bookings?.length) {
        const url = `${environment.apiUrl}/bookings/update`;
        const response = await this.http.put<BookingsResponse>(url, { bookings }).toPromise();
        if (response.code === 100) {
          this.traineeBookings$.next(response.data);
        }
        return response;
      }
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Unable to connect with the API server.'
        } ]
      };
    }
  }

  getTraineeBookings$(): Observable<Booking[]> {
    return this.traineeBookings$;
  }

  getDetails$(bookingId: number): Observable<BookingResponse> {
    return this.http.get<BookingResponse>(`${environment.apiUrl}/bookings/${bookingId}`).pipe(
        catchError(err => of({
          code: 999,
          status: 'FAILED',
          errors: [{
            code: 'COMM_ERR',
            desc: 'Communication failure ' + err.message
          }]
        }))
    );
  }

  async getPayments(bookingId: number): Promise<PaymentsResponse> {
    try {
      return await this.http.get<PaymentsResponse>(`${environment.apiUrl}/bookings/${bookingId}/payments`)
          .toPromise();
    }
    catch (err) {
      return {
        code: 999,
        status: 'FAILED',
        errors: [ {
          code: 'COMM_ERR',
          desc: 'Communication failure ' + err.message
        }]
      };
    }
  }
}
