import { User } from './user';
import { Attendance } from './attendance';
import { PaymentStatus } from './payment';
import { Schedule } from './schedule';

export interface Booking {
  id: number;
  attendee: User;
  dateBooked: Date;
  schedule: Schedule;
  status: BookingStatus;
  attendance: Attendance;
  confirmedBy?: string;
  saf100?: string;
  dateIssued?: Date;
  paymentStatus?: PaymentStatus;
  attended?: boolean;
  attendStart?: string;
  attendEnd?: string;
  paid?: boolean;
  cancel?: boolean;
  expanded?: boolean;
}

/**
 * The order of the status matters and should match from <strong><code>BookingStatus.java</code></strong>
 */
export enum BookingStatus {
  CONFIRMED,
  ISSUED,
  COMPLETED,
  CANCELLED
}
